
$primary: #00838F;
// $secondary: #54595F;
$secondary: #D7EAF0;
$white: white;
$color-text: #7A7A7A;
$color-orange: #FF6900;
$orange: #FF6900;
$color-gray: #e3e4e4;
$label: white;

$color-cancel: #7A7A7A;
$color-modal: #E2E2E2;
$color-button: #7A7A7A;

$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'Lato', sans-serif;


//table

$color-level1: #00838F;
$color-level2: #7A7A7A;
$color-level3: #969696;
$color-level4: rgb(175, 174, 174);
