/* You can add global styles to this file, and also import other style files */

@import "src/app/scss/config";


//view

.padding-view{
    padding-top: 8vh;
}

.padding-card{
    padding-top: 8vh;
}

.header-forms{
    background-color: $color-orange;
    margin: 0px;
    padding: 10px 0px 5px 0px;
}

.header-title-forms{
    font-size: 22px;
    font-family: $primary;
    font-weight: 500;
}

.body-forms{
    background-color: $color-gray;
}

// .forms-text{
//     font-size: 17px;
//     // color: $primary;
// }

.sub-headers-forms{
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 0px 4px 0px;
}

.title-forms{
    font-size: 19px;
    color: white;
    font-weight: 500;
    padding: 0px;
    margin: 0px;
}

.button-send-forms{
    background-color: $primary;
    color: white;
}

.text-area-form{
    height: 100px;
}

.footer-button{
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.save-button{
    background-color: $primary !important;
    color: $label !important;
    height: 35px;
    font-weight: 500 !important;
    font-size: 12px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    border: rgb(255, 255, 255) solid 1px !important;
}

.cancel-button{
    background-color: $color-cancel !important;
    color: $label !important;
    height: 35px;
    font-weight: 500 !important;
    font-size: 12px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    border: rgb(255, 255, 255) solid 1px !important;
}




//Tables

.head-tables{
    background-color: $primary;
}

.title-tables{
    color: white;
}

.header-div{

    display: flex;
    justify-content: space-between;
    // align-items: center;
    
}

.add-icon{
    // position: absolute;
    font-size: 25px;
    columns: white;
    // margin-left: 100px;
    // padding-left: 67vw;
}

.body-tables{
    background-color: white;
}

.footer-tables{
    background-color: white;
}




//LOGIN

.container-login{
    background-color: white;
    margin-left: 15vw;
    margin-right: 15vw;
    padding-top: 7vh;
    padding-bottom: 7vh;
    // border: 2px solid #bb4d00;
    border-radius: 7px;  
    // min-height: 62vh;
    // max-height: 70vh;
  }

  .title-login{

    color: $primary;
    font-weight: 500;
  }
  
  .text-recover{
  
    font-size: 15px;
    color: $primary;
    font-weight: 500;
  }

// 


// .breadcrumbs-link{
//   color: $primary;
//   font-weight: 500;
// }



//Modal

.header-div{

    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $color-orange;
    margin: 0px;
    padding: 10px 0px 5px 0px;
}

.modal-title-head{

    color: white;
    font-size: 20px;
    font-family: $primary;
    font-weight: 500;
    padding: 3px 0px 7px 0px;
    margin: 0px;
}

.close-button{

    color: white;
    font-size: 35px !important;
    font-weight: 500;
    padding: 0px;
    margin: 0px;
}

.modals-body{
    background-color: $color-modal;
}

.modals-subtitle{

    font-weight: 500;
}

.modals-footer{
    background-color: $color-modal;
}



//Backoffice

//View Cards

.background-preview {
    height: 100vh;
    background-image: url("/assets/backPreview.png");
    background-size: 100% 100%;
    background-repeat:no-repeat;
    background-position: center center;
    display: sticky;
    overflow: auto;
    }

.breadcrumb-style{
    margin-top: 12px;
    height: 50px;
}

.title-preview{
    color: $primary;
    font-family: $font-primary;
    font-weight: 700;
    font-size: 35px;
    margin-top: 20px;
    // width: 80vw;
  }

.sub-title-preview{

    color: $primary;
    font-size: 25px;
    font-weight: 500;
    padding-top: 10px;
}

.card-config{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    padding-top: 5px;
  }

  .icon-preview{
    padding-top: 14vh;
}


//Breadcrumbs

.breadcrumbs-link{
    color: $primary !important;
    font-weight: 500;
  }

.breadcrumbs-gray{
    color: $color-cancel !important;
    font-weight: 500;
  }

  //Table

.add-button{
    color: white;
}